// mixins/checkPhone.js
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      authPhone: state => state.phone.phone
    })
  },

  watch: {
    // 监听 authPhone 属性的变化
    authPhone (newValue, oldValue) {
      console.log('authPhone 变化了', newValue)
      // 监听到手机号已绑定

      this.$emit('phoneHaveBind')
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.authPhone) {
        // 如果手机号为空，显示提示弹窗或者其他操作
        console.log('手机号为空，需要进行提示')
        this.$emit('showPhonePopup')
        // this.$emit('test123')
      }
    })
  }
}
